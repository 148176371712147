<template>
    <v-container fluid>
      <navbar title="تعیین زمان ارسال" route = '/reciever'/>
      <v-main class="pt-15 d-flex flex-column justify-center" style="padding-bottom: 90px">
        <error-alert/>
        <v-card v-if="!error_time" class="pa-2 card flat-card" color="surface" width="380">
          <span class="mr-2">
            مرسوله 1
          </span>
        </v-card>
        <orders :cargos='slides' :error_time='error_time'/>
        <v-skeleton-loader
        v-if="!slides"
      v-bind="attrs"
      type="list-item-two-line, list-item-two-line, list-item-two-line, list-item-two-line, list-item-two-line" max-width="100%" height="400"
    ></v-skeleton-loader>
        <div v-if="slides">
          <settime v-if="slides.deliveries.length > 1"/>
        <send v-if="slides.deliveries.length == 1" />
        </div>
        <div>
          <bfooter title="شیوه پرداخت"/>
        </div> 
      </v-main>
    </v-container>
</template>
<script>
import navbar from '@/components/navbar/appBar'
import orders from './components/cards/basketOrders'
import settime from './components/payment/deliveryTimeSlider'
import bfooter from './components/buttons/basketFooterBtn'
import { view_times_deliveries, viewbasket_products } from './models/Basket'
import { mapState } from 'vuex'
import store from '@/store'
import ErrorAlert from '@/components/alerts/error.alert.vue'
import send from './components/cards/sendTimeCard'
export default {
   components: {
     navbar,
     orders,
     settime,
     bfooter,
      ErrorAlert,
      send
   },
   computed: {
     ...mapState({
       error_time: state => state.basket.items_error_basket,
       loading: state => state.loading_page,
       slides: state => state.basket.delivery_Time.deliveries
     })
   },
   data: () => ({
       errorSend: false,
       attrs: {
      boilerplate: false,
      elevation: 0,
    },
   }),
   created () {
     view_times_deliveries()
     viewbasket_products()
     store.dispatch('clear_time')
   }
}
</script>